import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const ImageBar = ({isHome}) => (
    <StaticQuery
    query={graphql`
        {
            background: file(relativePath: { eq: "PanoramaMar24th200151_crop.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, cropFocus: CENTER, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `}
    render={ data => (
        <Img fluid={data.background.childImageSharp.fluid} alt="" className={`imageBar ${isHome ? 'is-home' : ''}`} />
    )}
    />
)

export default ImageBar