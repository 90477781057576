import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import logo from "../images/logo-2.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faCaretRight, faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
      moreInfoActive: false,
      dropdownActive: false,
      dropdownId: null,
    }


    //bind this
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleMoreInfo = this.toggleMoreInfo.bind(this)
  }

  

  toggleMenu() {
    document.querySelector(".navbar-burger").classList.toggle("is-active")
    document.querySelector(".navbar-menu").classList.toggle("is-active")
    console.log("menu pressed")
  }

  toggleMoreInfo() {
    this.setState({'moreInfoActive': !this.state.moreInfoActive})
  }
  activateDropdown(id) {
    this.setState({'dropdownActive': true})
    this.setState({'dropdownId': id})
  }
  toggleDropdown(id) {
    if(this.state.dropdownId === id) {
      if(this.state.dropdownActive === true ) {
        this.setState({'dropdownActive': false})
      } else {
        this.setState({'dropdownActive': true})
      }
    } else {
        this.setState({'dropdownId': id})
        this.setState({'dropdownActive': true})
    }
  }
  deactivateDropdown() {
    this.setState({'dropdownActive': false})
  }
  dropdownSetClass(id) {
    if(this.state.dropdownActive && this.state.dropdownId === id) {
      return 'is-active'
    } else {
      return ''
    }
  }
  navClass() {
    if(this.state.dropdownActive) {
      return 'is-active'
    } else {
      return ''
    }
  }
  render() {
    let Nav = (
      <>
      <StaticQuery 
        query={graphql`
          query {
            graphAPI {
              navigation {
                linkList {
                  ... on GRAPHAPI_ComponentMetaLink {
                    id
                    __typename
                    title
                    url
                  }
                  ... on GRAPHAPI_ComponentMetaDropdown {
                    id
                    __typename
                    title
                    links {
                      __typename
                      title
                      url
                      id
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (<>
          {data.graphAPI.navigation.linkList.map( link => {
            if(link.__typename === "GRAPHAPI_ComponentMetaLink") {
              return (
                <Link key={link.id} to={link.url} className="navbar-item">{link.title}</Link>
              )
            }
            else if(link.__typename === "GRAPHAPI_ComponentMetaDropdown") {
              return (
                  <div key={link.id} tabindex="0" className={`navbar-item has-dropdown is-hoverable ${this.dropdownSetClass(link.id)}`}>
                  <a onClick={() => {this.toggleDropdown(link.id)}} className="navbar-link">{link.title}</a>
                  <div className="navbar-dropdown">
                    {link.links.map(sublink => (
                      <Link key={sublink.id} to={sublink.url} className="navbar-item">{sublink.title}</Link>
                    ))}
                  </div>
                  </div>
              )
            }
          })}
        </>)} 
        />
      </>
    )

    return (
    <>
      <nav className="navbar transparent">
        <div className="container" style={{ alignItems: "center" }}>
            <Link to="/" className="logo"><img src={logo} alt=""></img></Link>
            <div className="navbar-brand  is-hidden-desktop ">
              <button
                className="button navbar-burger"
                onClick={this.toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
          <div className="navbar-menu">
            <div className="navbar-brand is-hidden-touch is-adjusted">
              <button
                className="button navbar-burger"
                onClick={this.toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
            <div className="navbar-end">
              {Nav}
            </div>
          </div>
        </div>
      </nav>
    </>
  )}
}


export default Header
